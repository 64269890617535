<template>
    <div class="mx-3">
        <div :id="captchaId"></div>

        <acs-cart-error :errors="errors" />

        <template v-if="!direct">
            <acs-pay-card-list
                v-if="!isOnlyTeam && !isOnlyTerminal"
                ref="providerCard"
                v-model="paymentMethod"
                :slug="slug"
                :disabled="loading"
                :required="payCardSelectedRequired"
                :show-list.sync="showCardList"
                :loading-indicator.sync="loading"
                @expanded="onCardListExpand"
                class="pb-0"
            />
            <v-list dense two-line class="pt-0" :disabled="loading">
                <v-list-item-group v-model="selected" color="primary">
                    <template v-for="psp in pspsNotCompliance">
                        <v-list-item
                            :key="psp.provider"
                            class="acs-card-list-item"
                            @click="selectPsp(psp)">
                            <v-list-item-icon class="acc-icon--center">
                                <v-icon large :color="metadata[psp.provider] && metadata[psp.provider].color ? metadata[psp.provider].color : undefined">$vuetify.icons.psp_{{ psp.provider }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t(`shared-front:psp.providers.${psp.provider}.title`) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-if="!isOnlyTeam && !isOnlyTerminal"
                        class="acs-card-list-item"
                        @click="selectPsp(pspCompliance)">
                        <v-list-item-icon class="acc-icon--center">
                            <v-icon large color="primary">$vuetify.icons.ecomCard</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">{{ $t('payrequest.custom') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <component
                v-if="psp"
                v-model="paymentFormValid"
                ref="provider"
                :type="type"
                :slug="slug"
                :psp="psp"
                :psp-data="checkoutPaymentData"
                :is="pspComponentName"
                :disabled="disabled"
                :terminal="terminal"
                :loading-indicator.sync="loading">
                <template #paybutton>
                    <v-btn
                        class="primary mt-5"
                        block
                        rounded
                        @click="pay"
                        :disabled="disabled"
                        :loading="loading">
                        {{ $t('cartpay.submit') }}!
                    </v-btn>
                </template>
            </component>
        </template>

        <template v-if="!psp">
            <v-btn
                class="primary mt-5"
                block
                rounded
                @click="direct ? pay() : payByRegisteredCard()"
                :disabled="disabled"
                :loading="loading">
                {{ direct ? $t('cartpay.submitdirect') : $t('cartpay.submit') }}
            </v-btn>
        </template>

        <v-input type="hidden" value="" :rules="valid ? [] : [$required]" />
    </div>
</template>

<script>
import AcsPayCardList from '@/components/pay/AcsPayCardList'
import RouteMixin from '@/mixins/RouteMixin'
import AcsCartError from '@/components/cart/AcsCartError'

import AcsPspProviderStripe from '@/components/psp/AcsPspProviderStripe'
import AcsPspProviderSbp from '@/components/psp/AcsPspProviderSbp'
import AcsPspProviderDemo from '@/components/psp/AcsPspProviderDemo'
import AcsPspProviderCashregister from '@/components/psp/AcsPspProviderCashregister'
import AcsPspProviderTerminal from '@/components/psp/AcsPspProviderTerminal'
import AcsPspProviderSaferpay from '@/components/psp/AcsPspProviderSaferpay'

const providers = [
    AcsPspProviderStripe, AcsPspProviderSbp, AcsPspProviderDemo,
    AcsPspProviderCashregister, AcsPspProviderSaferpay
]

export default {
    name: 'acs-cart-pay-process',
    components: {
        AcsPayCardList,
        AcsCartError,
        AcsPspProviderStripe,
        AcsPspProviderSbp,
        AcsPspProviderDemo,
        AcsPspProviderCashregister,
        AcsPspProviderTerminal,
        AcsPspProviderSaferpay
    },
    props: {
        type: { type: String },
        disabled: { type: Boolean },
        slug: { type: String },
        aslot: { type: String },
        step: { type: Number, default: 0 },
        direct: { type: Boolean },
        checkoutParams: { type: Object },
        captchaEnabled: { type: Boolean },
        terminal: { type: Boolean }
    },
    mixins: [RouteMixin],
    data() {
        return {
            psp: null,
            loading: false,
            selected: null,
            showCardList: false,
            checkoutPaymentData: {},
            paymentMethod: null,
            paymentFormValid: false,
            captchaId: 'h-captcha',
            captchaWidgetId: null,
            errors: [],
            pspComponentInterval: null,
            metadata: providers.reduce((acc, provider) => {
                if (!provider.metadata?.provider) {
                    return acc
                }
                acc[provider.metadata.provider] = provider.metadata
                return acc
            }, {})
        }
    },

    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        connected() {
            return this.$store.getters['auth/connected']
        },
        pspsNotCompliance() {
            const psp = this.$store.getters['psp/psps'].filter(psp => !psp.compliance)
            return psp.filter(psp => this.displayPsp(psp))
        },
        pspCompliance() {
            return this.$store.getters['psp/psps'].find(psp => psp.compliance)
        },
        pspComponentName() {
            return this.psp && `acs-psp-provider-${this.psp}`
        },
        payCardSelectedRequired() {
            return !this.psp
        },
        isOnlyTeam() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'team')
        },
        isOnlyTerminal() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'terminal')
        },
        pspCash() {
            return this.$store.getters['psp/psps'].find(psp => psp.provider === 'cashregister')
        },
        pspTerminal() {
            return this.$store.getters['psp/psps'].find(psp => psp.provider === 'terminal')
        },
        valid() {
            return this.direct || !!this.psp || (!this.psp && !!this.paymentMethod)
        }
    },

    beforeDestroy() {
        this.reset()
    },

    async mounted() {
        await this.$store.dispatch('psp/list', { slug: this.slug })

        if (this.captchaEnabled) {
            // eslint-disable-next-line
            this.captchaWidgetId = hcaptcha.render(this.captchaId, {
                sitekey: this.$config.hcaptcha.siteKey
            })
        }

        this.reset()

        if (this.isOnlyTeam && this.pspCash) {
            return this.selectPsp(this.pspCash)
        }
        if (this.isOnlyTerminal && this.pspTerminal) {
            return this.selectPsp(this.pspTerminal)
        }
    },

    methods: {

        displayPsp(psp) {
            if (this.isOnlyTeam && psp.provider !== 'cashregister') {
                return false
            }
            if (this.isOnlyTerminal && psp.provider !== 'terminal') {
                return false
            }
            return true
        },

        onCardListExpand(expanded) {
            this.psp = expanded ? null : this.psp
            this.selected = expanded ? null : this.selected
        },

        async selectPsp(psp) {
            try {
                window.clearInterval(this.pspComponentInterval)
                const index = this.pspsNotCompliance.concat([this.pspCompliance]).findIndex(p => p.provider === psp.provider)
                this.selected = index

                this.errors = []
                this.loading = true
                this.psp = null

                await this.checkoutValidate()

                this.showCardList = false
                this.psp = psp.provider

                const checkoutData = await this.$store.dispatch('psp/getCheckoutData', {
                    slug: this.slug,
                    psp: psp.provider
                })

                const paymentData = this.checkout(psp.provider)

                // si le component n'est pas encore disponible, on attend avant
                // de le rappeler, mais on stoppe après quelques secondes
                if (!this.$refs.provider) {
                    await new Promise((resolve, reject) => {
                        let cpt = 0
                        this.pspComponentInterval = window.setInterval(() => {
                            if (this.$refs.provider) {
                                window.clearInterval(this.pspComponentInterval)
                                return resolve()
                            }
                            cpt += 1
                            if (cpt >= 20) {
                                reject(new Error(this.$i18n.t(`PSP component ${psp.provider} not found`)))
                            }
                        }, 250)
                    })
                }

                const route = this.getSuccessRoute()
                await this.refreshToken()

                return this.$refs.provider.onSelect(checkoutData, paymentData, route)
            } catch (err) {
                return this.handleError(err)
            }
        },

        autoReloadPsp() {
            if (this.isOnlyTeam && this.pspCash) {
                return this.selectPsp(this.pspCash)
            }
            if (this.isOnlyTerminal && this.pspTerminal) {
                return this.selectPsp(this.pspTerminal)
            }
            return Promise.resolve()
        },

        reset(softReset) {
            this.errors = []
            // lorsqu'on change le montant (tip ou discount), le form est resetté.
            // mais pour le mode cashregister notamment, il ne faut rien toucher
            if (softReset && ((this.isOnlyTeam && this.pspCash) || (this.isOnlyTerminal && this.pspTerminal))) {
                return
            }

            this.selected = null
            this.psp = null
            window.clearInterval(this.pspComponentInterval)
            const el = this.$refs.provider
            if (el) {
                return el.reset()
            }
        },

        getCaptchaToken() {
            let token = null
            if (this.captchaWidgetId) {
                // eslint-disable-next-line
                token = hcaptcha.getResponse(this.captchaWidgetId)
                if (!token) {
                    return this.$err(new Error(this.$i18n.t('shared-front:errors.captcha.empty')))
                }
            }
            return token
        },

        getSuccessRoute() {
            const name = this.type === 'bill'
                ? 'paysuccess'
                : 'cartsuccess'

            return this.$router.resolve({
                name: this.getContextRouteName(name),
                params: { ...this.$route.params }
            })
        },

        checkoutValidate() {
            return this.type === 'bill' || this.terminal
                ? Promise.resolve()
                : this.$store.dispatch('cart/validateLast', { slug: this.slug })
        },

        checkout(psp, opts = {}) {
            const checkoutAction = this.terminal
                ? 'psp/checkoutTerminal'
                : (this.type === 'bill'
                    ? 'psp/checkoutBill'
                    : 'psp/checkout'
                )

            return {
                action: checkoutAction,
                actionData: {
                    data: {
                        ...(this.checkoutParams || {}),
                        ...opts
                    },
                    psp,
                    slug: this.slug,
                    captcha_token: this.getCaptchaToken()
                }
            }
        },

        async pay() {
            try {
                if (this.loading || this.disabled) {
                    return
                }
                this.loading = true
                this.errors = []

                const route = this.getSuccessRoute()

                await this.checkoutValidate()
                await this.refreshToken()

                if (this.direct) {
                    // uniquement possible depuis le cart-ecommerce

                    await this.$store.dispatch('dbg/log', {
                        slug: this.slug,
                        cid: this.$store.getters['cart/cart']?.id,
                        loading: this.loading,
                        msg: 'before direct order'
                    })

                    await this.$store.dispatch('psp/directOrder', { slug: this.slug, slotSlug: this.aslot })

                    return this.$router.push({ name: this.getContextRouteName('cartsuccess') })
                }

                // la redirection se fait automatiquement via le composant
                await this.$refs.provider.onConfirmPayment(route)
            } catch (err) {
                return this.handleError(err)
            }
        },

        async payByRegisteredCard() {
            try {
                if (this.loading || this.disabled || !this.paymentMethod) {
                    return
                }
                this.loading = true
                this.errors = []

                await this.checkoutValidate()

                const route = this.getSuccessRoute()

                const checkoutData = await this.$store.dispatch('psp/getCheckoutData', {
                    slug: this.slug,
                    psp: this.pspCompliance.provider
                })

                const paymentData = this.checkout(this.pspCompliance.provider, {
                    payment_method_id: this.paymentMethod
                })

                await this.refreshToken()

                // la redirection se fait automatiquement via le composant
                await this.$refs.providerCard.onConfirmPayment(route, checkoutData, paymentData)
            } catch (err) {
                return this.handleError(err)
            }
        },

        refreshToken() {
            if (!this.connected) {
                return Promise.resolve()
            }
            // on refresh le token s'il y a un user connecté. En effet, toutes les actions
            // de panier/commandes sont publiques (puisqu'on peut tout fare sans être connecté).
            // Or, si on est connecté, certaines infos de compte sont inscrites dans la
            // commandes. On doit s'assurer que le token est toujours valide dans ce cas
            return this.$store.dispatch('auth/refreshToken')
        },

        handleError(err) {
            this.loading = false
            window.clearInterval(this.pspComponentInterval)

            // eslint-disable-next-line
            this.captchaWidgetId && hcaptcha.reset(this.captchaWidgetId)
            if (err?.response?.status === 422) {
                this.errors = err.response.data?.errors || []
            } else {
                this.$err(err, { silent: true })
            }
        }
    }
}
</script>

<style lang="sass">
.acs-card-list-item
    .v-list-item__title, .v-list-item__subtitle
        font-size: 0.875rem !important
</style>
