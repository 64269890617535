import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'
import fr from 'vuetify/es5/locale/fr'
import it from 'vuetify/es5/locale/it'
import nl from 'vuetify/es5/locale/nl'
import pt from 'vuetify/es5/locale/pt'
import ro from 'vuetify/es5/locale/ro'
import ru from 'vuetify/es5/locale/ru'
import zh from 'vuetify/es5/locale/zh-Hans'

export default {
    theme: {
        themes: {
            light: {
                primary: '#05B7FE',
                secondary: '#499CAE',
                accent: '#FF004F'
            }
        }
    },
    icons: {
        iconfont: 'mdi',
        values: {
            // base
            baseHelp: 'mdi-help-circle',
            baseHelpOn: 'mdi-help-circle-outline',
            baseInfo: 'mdi-information',
            baseSave: 'mdi-content-save',
            baseClose: 'mdi-close',
            baseDelete: 'mdi-delete',
            baseDeleteConfirm: 'mdi-check',
            baseBack: 'mdi-chevron-left',
            baseMenu: 'mdi-menu',
            baseMenuVert: 'mdi-dots-vertical',
            baseAdd: 'mdi-plus',
            baseEdit: 'mdi-pencil',
            baseDown: 'mdi-chevron-down',
            baseUp: 'mdi-chevron-up',
            baseRight: 'mdi-chevron-right',
            baseLeft: 'mdi-chevron-left',
            baseMenuLeft: 'mdi-menu-left',
            baseMenuRight: 'mdi-menu-right',
            baseSearch: 'mdi-magnify',
            baseQr: 'mdi-qrcode',
            baseMenuDown: 'mdi-menu-down',
            basePolicy: 'mdi-file-sign',
            baseDownload: 'mdi-download',
            baseContact: 'mdi-email-fast-outline',
            // ticket
            ticketX: 'mdi-file-document-outline',
            // psp
            pspCashregister_cash: 'mdi-cash-100',
            pspCashregister_card: 'mdi-credit-card',
            pspCashregister_debit: 'mdi-invoice-text-edit-outline',
            pspCashregister_terminal: 'mdi-qrcode',
            pspCashregister_reka: 'mdi-wallet-giftcard',
            pspCashregister_lunchcheck: 'mdi-wallet-giftcard'
        }
    },
    lang: {
        locales: { de, en, es, fr, it, nl, pt, ro, ru, zh },
        current: 'fr'
    }
}
