<template>
    <v-dialog v-model="show" max-width="200" :persistent="wheeling">
        <template #activator="{ on, attrs }">
            <v-btn
                rounded
                outlined
                color="primary"
                :disabled="disabled"
                v-on="on"
                v-bind="attrs"
                small>
                {{ $t('product.quantity') }}: {{ value }}
                <v-icon right small>$vuetify.icons.baseDown</v-icon>
            </v-btn>
        </template>
        <v-card>
            <acs-base-card-title
                :title="$t('product.quantity')"
                @close="show = false"
                :dismissible="!wheeling" />

            <v-btn @click="add(1)" block text depressed color="primary">
                <v-icon>$vuetify.icons.baseUp</v-icon>
            </v-btn>
            <picker
                ref="picker"
                :options="quantities"
                v-model="selected"
                :item-height="40"
                :visible-options-amount="visibleOptions"
                arrows>
            </picker>
            <v-btn @click="add(-1)" block text depressed color="primary">
                <v-icon>$vuetify.icons.baseDown</v-icon>
            </v-btn>

            <v-card-actions>
                <v-btn
                    color="primary"
                    block rounded text
                    @click="save"
                    :disabled="wheeling">
                    {{ $t('shared-front:base.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import Picker from 'vue-wheel-picker'

export default {
    name: 'acs-cart-product-quantity',
    components: { Picker, AcsBaseCardTitle },
    props: {
        value: { type: [Number, String] },
        min: { type: Number },
        max: { type: Number },
        disabled: { type: Boolean }
    },
    data: () => ({
        pickerRef: null,
        wheeling: false,
        show: false,
        selected: { value: '0', text: '0' },
        quantities: []
    }),
    computed: {
        minimum() {
            return this.min || 1
        },
        maximum() {
            return this.max || 999
        },
        visibleOptions() {
            const nb = (2 * Math.round((this.maximum - this.minimum) / 2)) - 2
            return nb < 10 ? nb : 10
        }
    },
    watch: {
        'pickerRef.wheeling': {
            handler(v) {
                this.wheeling = v
            },
            deep: true
        },
        async show(v) {
            if (v && !this.pickerRef) {
                await this.$nextTick()
                this.$set(this, 'pickerRef', this.$refs.picker)
            }
        }
    },
    beforeDestroy() {
        delete this.pickerRef
    },
    mounted() {
        for (let i = this.minimum; i <= this.maximum; i += 1) {
            this.quantities.push({
                value: i.toString(),
                text: i.toString()
            })
        }
        this.init()
    },
    methods: {
        init() {
            const value = this.constraintValue(this.value)
            this.selected.value = value.toString()
            this.selected.text = value.toString()
        },

        add(num) {
            const value = this.constraintValue(Number(this.selected.value) + num)
            this.selected = { value: value.toString(), text: value.toString() }
        },

        save() {
            this.$emit('input', Number(this.selected.value))
            this.show = false
        },

        constraintValue(value) {
            if (value < this.minimum) {
                return this.minimum
            } else if (value > this.maximum) {
                return this.maximum
            }
            return value
        }
    }
}
</script>

<style lang="sass">
.picker
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center

    height: 120px
    border-radius: 4px

    text-align: center
    font-size: 16px
    color: #bbbcc9

    .picker_chosen
        background-color: #F8F8FA
        border-top: 1px solid #dddde4
        border-bottom: 1px solid #dddde4
        color: black
        font-size: 22px
        height: 32px !important
        line-height: 32px !important

    .picker_chosen_list
        padding-left: 0

</style>
