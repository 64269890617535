<template>
    <div>
        <acs-base-loader v-model="loading" permanent />
        <iframe
            class="asc-saferpay-iframe"
            allowpaymentrequest=""
            :src="paymentPageUrl"
            :style="{ height: `${height}px` }"
        />
    </div>
</template>

<script>
export default {
    name: 'acs-psp-provider-saferpay',

    props: {
        loadingIndicator: { type: Boolean },
        psp: { type: String },
        pspData: { type: Object },
        slug: { type: String },
        disabled: { type: Boolean },
        terminal: { type: Boolean }
    },

    data() {
        return {
            checkoutAction: {},
            paymentPageUrl: null,
            height: 450,
            redirectUrl: null,
            iFrameHeightChange: e => this.onIframeHeightChange(e)
        }
    },

    computed: {
        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loadingIndicator', v)
            }
        }
    },

    mounted() {
        window.addEventListener('message', this.iFrameHeightChange)
    },

    beforeDestroy() {
        window.removeEventListener('message', this.iFrameHeightChange)
    },

    methods: {

        async onSelect(checkoutData, { action, actionData }, route) {
            try {
                this.loading = true
                this.paymentPageUrl = null
                this.redirectUrl = route.href

                const { url } = await this.$store.dispatch(action, actionData)

                this.paymentPageUrl = url
            } catch (err) {
                this.loading = false
                return this.$err(err)
            }
        },

        async onConfirmPayment(route) {
            if (this.disabled) {
                return
            }
            const { action, actionData } = this.checkoutAction

            this.loading = true

            const res = await this.$store.dispatch(action, actionData)
            if (!res.checkoutUrl) {
                throw new Error('No checkout URL found!')
            }
            window.location.href = res.checkoutUrl
            // on ne stoppe pas le load, puisqu'il y a redirect
        },

        reset() {
            this.checkoutAction = {}
        },

        onIframeHeightChange(e) {
            this.loading = false
            if (!this.terminal && e.data.message === 'acc-loaded') {
                window.location.href = this.$config.front + this.redirectUrl
                return
            }
            this.height = (e.data.height || 0) > 450
                ? e.data.height + 25
                : 450
        }
    }
}
</script>

<style lang="sass">
.asc-saferpay-iframe
    width: 100%
    border: 0
</style>
